import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReCaptcha } from 'react-recaptcha-v3';

import useIntl from 'components/useIntl';
import Input from './Input';
import Select from './Select';
import Radio from './Radio';
import Checkbox from './Checkbox';
import Button from 'components/Button';

import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import { recaptcha } from 'src/config/app.conf';

const Form = () => {
  const { t, getLocaleURL } = useIntl();

  useEffect(() => {
    document.getElementById('are_you_simulated').style.display = 'none';
  }, []);

  let submitting = false;

  const submitRegistrationForm = (element) => {
    element.preventDefault();
    let form = document.querySelector(
      'body#spark-registration-form form, form#spark-registration-form'
    );
    let missing = '';
    let required = { contact_email: 'Email' };
    let customRequired = document.querySelectorAll(
      'input:required, textarea:required, select:required'
    );
    let questionsRequired = {
      answers_8167: 'How did you hear about Riviera?',
      agent: 'Are you a realtor?',
      answers_8210: 'Please check to confirm your interest',
    };

    // Adds custom required inputs to the 'required' object
    for (let i = 0; i < customRequired.length; i++) {
      if (
        customRequired[i].id === 'agentYes' ||
        customRequired[i].id === 'agentNo'
      ) {
        continue;
      } else {
        required[customRequired[i].id] = customRequired[
          i
        ].parentNode.firstElementChild.innerHTML.replace('*', '');
      }
    }

    // Adds required question inputs to the 'required' object
    for (let key in questionsRequired) {
      required[key] = questionsRequired[key];
    }

    // Iterates through required fields and adds any that have
    // not been populated to 'missing' list
    for (let key in required) {
      let elements = Array.from(
        document.querySelectorAll("[id^='" + key + "']")
      );

      if (elements.length > 0) {
        let missing_field = true;

        elements.forEach(function (el) {
          if (
            el.length < 1 ||
            (el &&
              ((el.type.toLowerCase() === 'checkbox' && el.checked) ||
                (el.type.toLowerCase() === 'radio' && el.checked) ||
                (el.type.toLowerCase() !== 'radio' &&
                  el.type !== 'checkbox' &&
                  el.value) ||
                (document.getElementById(key + '_other_text') &&
                  document.getElementById(key + '_other_text').value)))
          ) {
            missing_field = false;
          }
        });

        if (missing_field) {
          // console.log(required);
          missing += '- ' + required[key] + '\r\n';
        }
      }
    }

    // Tests email input value against RFC 5322 Official Standard Email Regex
    var email = document.getElementById('contact_email').value;
    if (
      // eslint-disable-next-line no-control-regex
      !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
      )
    ) {
      missing += '- Email is invalid\r\n';
    }

    if (missing !== '') {
      alert('The following fields are incomplete:\r\n' + missing);
      return false;
    }

    // Prevents duplicate submissions
    if (submitting) {
      return false;
    }
    submitting = true;

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(recaptcha, {
          action: 'registration',
        })
        .then(function (token) {
          const tokenInput = document.querySelector('.g-recaptcha-response');
          if (tokenInput) {
            tokenInput.value = token;
            form.appendChild(tokenInput);
          }
          form.submit();
        });
    });

    submitting = false;
  };

  return (
    <Root
      id="spark-registration-form"
      action="https://spark.re/led-mac/riviera/register"
      accept-charset="UTF-8"
      method="post"
      onSubmit={submitRegistrationForm}
    >
      <input name="utf8" type="hidden" value="&#x2713;" />
      <input
        type="hidden"
        name="authenticity_token"
        value="Ufn3Ub/BSB7sMqCQR8lHUHjlbTb4xFmOzDa0laJM2Wek+iW6/5y3D/PnUuTVrtFoBw2VqCiBUAGn1QNdHnOTyQ=="
      />
      <input type="hidden" name="source" id="source" value="Website" />
      <input
        type="hidden"
        name="redirect_success"
        id="redirect_success"
        value={`${window.location.origin}${getLocaleURL()}/thank-you`}
      />
      <input
        type="hidden"
        name="redirect_error"
        id="redirect_error"
        value={window.location.origin}
      />
      <input
        type="text"
        name="are_you_simulated"
        id="are_you_simulated"
        placeholder="Leave this field blank"
      />
      <Container>
        <div>
          <Input
            name="contact[first_name]"
            id="contact_first_name"
            required
            label={t.form.firstname}
          />
          <Input
            name="contact[last_name]"
            id="contact_last_name"
            required
            label={t.form.lastname}
          />
          <Input
            name="contact[email]"
            id="contact_email"
            type="email"
            required
            label={t.form.email}
          />
          <Input
            name="contact[phone]"
            id="contact_phone"
            type="tel"
            required
            label={t.form.phone}
          />
          <Input
            name="contact[postcode]"
            id="contact_postcode"
            required
            label={t.form.zip}
          />
        </div>
        <div>
          <Select
            name="answers[8841][answers]"
            id="answers_8841"
            required
            label={t.form.howHeard}
            options={t.form.howHeardOptions}
          />
          <Select
            name="answers[9154][answers]"
            id="answers_9154"
            label={t.form.whatInquire}
            options={t.form.whatInquireOptions}
          />
          <Radio
            name="agent"
            id="agent"
            required
            label={t.form.realtor}
            options={[
              {
                label: t.form.yes,
                value: 'Yes',
              },
              {
                label: t.form.no,
                value: 'No',
              },
            ]}
          />
          <Radio
            name="answers[9156][answers]"
            id="answers_9156"
            label={t.form.workingWithRealtor}
            options={[
              {
                label: t.form.yes,
                value: 'Yes',
              },
              {
                label: t.form.no,
                value: 'No',
              },
            ]}
          />
          <Input
            name="answers[9156][answers]"
            id="answers_9156"
            label={t.form.ifYesRealtor}
          />
        </div>
      </Container>
      <Checkbox
        name="answers[9157][answers][]"
        id="answers_9157_yes--i-have-expressed-consent"
        label={t.form.disclaimer}
      />
      <Wrapper>
        <Button
          text={t.form.submit}
          handleClick={(f) => f}
          button
          type="submit"
        />
      </Wrapper>
      <ReCaptcha sitekey={recaptcha} action="registration" />
    </Root>
  );
};

const Root = styled.form``;

const Container = styled.div`
  @media ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-column-gap: ${vwDesktop(30)};
    > div {
      width: ${vwDesktop(400)};
    }
  }
`;

// const Line = styled.span`
//   @media ${media.desktop} {
//     display: block;
//     width: 1px;
//     height: ${vwDesktop(426)};
//     background-color: #dddddd;
//     margin: 0 ${vwDesktop(39)} 0 ${vwDesktop(40)};
//   }
// `;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${media.desktop} {
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }
`;

export default Form;
